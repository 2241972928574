// Core
import { useState } from 'react'
// Styles
import Styles from './Styles.module.scss'
// MUI
import { Modal } from '@mui/material'
// Icons
import { ReactComponent as CloseIcon } from '../../../theme/assets/icons/close_updated.svg'
// ApplePayButton
import { ApplePayButton, ThemeMode, SupportedNetworks, Scope, Environment, Locale, ButtonType, Edges } from '@tap-payments/apple-pay-button'
// Constants
import { paymentKey, APPLE_PAY_MERCHANT_DOMAIN, APPLE_PAY_MERCHANT_ID, ENVIRONMENT } from '../../../constants'
// Utils
import { capitalizeWord } from 'helpers'
// Components
import { PaymentPage } from '../TapGateway'

export const MacOSPayment = ({ open, onClose, openPaymentExpiresPopup, orderDetails }) => {
  const [isOpenTapMpdal, setIsOpenTapModal] = useState(false)

  const openTapPaymentModal = () => {
    // onClose()
    setIsOpenTapModal(true)
  }

  const handleCloseTapModal = () => {
    setIsOpenTapModal(false)
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className={Styles.main_container}>
        <div className={Styles.main_container__header}>
          <h6>Select payment method</h6>
          <CloseIcon onClick={onClose} className={Styles.icon} />
        </div>
        <ApplePayButton
          publicKey={paymentKey}
          merchant={{
            domain: APPLE_PAY_MERCHANT_DOMAIN,
            id: APPLE_PAY_MERCHANT_ID,
          }}
          transaction={{ amount: '1', currency: 'USD' }}
          environment={Environment?.[capitalizeWord(ENVIRONMENT)]}
          debug
          customer={{
            name: [
              {
                lang: Locale.EN,
                first: 'test',
                last: 'test',
              },
            ],
            contact: {
              email: 'test@gmail.com',
              phone: {
                countryCode: '+20',
                number: '10XXXXXX56',
              },
            },
          }}
          onReady={() => {
            console.log('Ready')
          }}
          onClick={(data) => {
            console.log('Clicked', data)
          }}
          onSuccess={async (token) => {
            console.log('Payment successful. Token:', token)
          }}
          scope={Scope.AppleToken}
          acceptance={{
            supportedBrands: [SupportedNetworks.Mada, SupportedNetworks.Visa, SupportedNetworks.MasterCard],
            supportedCards: ['DEBIT', 'CREDIT'],
            supportedCardsWithAuthentications: ['3DS', 'EMV'],
          }}
          interface={{
            locale: Locale.EN,
            theme: ThemeMode.DARK,
            type: ButtonType.PLAIN,
            edges: Edges.CURVED,
          }}
        />
        <p onClick={openTapPaymentModal}>Choose another payment method</p>
        {isOpenTapMpdal && <PaymentPage orderDetails={orderDetails} isOpen={isOpenTapMpdal} closePopup={handleCloseTapModal} openPaymentExpiresPopup={openPaymentExpiresPopup} />}
      </div>
    </Modal>
  )
}
