//Core
import { useEffect, useLayoutEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
//Styles
import Styles from './Styles.module.scss'
// Icons
import { ReactComponent as ArrowLeftIcon } from '../../../../theme/assets/icons/arrow_left_icon.svg'
import { ReactComponent as ArrowRightIcon } from '../../../../theme/assets/icons/arrow_right_icon.svg'
// Data
import { mandatory_questions_parent_onboarding } from '../../../../data'
import { MAIN_STATEMENTS_ANSWERS, ONBOARDING_STEP, PARENT_ONBOARDING_COOKIES_NAME, SKIPPED_VALUE, SUB_STATEMENTS_ANSWERS } from '../../../../data/enums'
// Utils
import { getLocalStorage, setLocalStorage } from '../../../../utils'
// i18n
import { useTranslation } from 'react-i18next'
import { translateMainAnswersQuestionnaire, translateQuestionnaire, translateSubAnswersQuestionnaire } from '../../../../data/locale.data'
import i18n from '../../../../i18n'
import ar from '../AddChild/locale.js/ar.json'
import en from '../AddChild/locale.js/en.json'
// MUI
import LinearProgress from '@mui/material/LinearProgress'

export const QuestionPartMandatory = ({ setOnboardingStep }) => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()

  const TYPE_OF_QUESTION = {
    MAIN: 'main',
    SUB: 'sub',
  }

  const [storage, setStorage] = useState(JSON.parse(getLocalStorage('questionnaire_0')))

  const [typeOfQuestion, setTypeOfQuestion] = useState(TYPE_OF_QUESTION.MAIN)

  const [isAnswered, setIsAnswered] = useState(false)

  const [mainStatementId, setMainStatementId] = useState(0)
  const [subStatementId, setSubStatementId] = useState(0)

  const [selectedLanguage, setSelectedLanguage] = useState(searchParams.get('lng'))

  const [totalQuestions, setTotalQuestions] = useState(0)
  const [amountOfAnsweredQuestions, setAmountOfAnsweredQuestions] = useState(0)
  const [answerObject, setAnswerObject] = useState({})

  useEffect(() => {
    let totalSubstatments = 0
    const totalMainStatments = mandatory_questions_parent_onboarding?.length

    for (const section of mandatory_questions_parent_onboarding) {
      totalSubstatments = totalSubstatments + section?.sub_statements?.length
    }

    setTotalQuestions(totalMainStatments + totalSubstatments)

    for (let i = 0; i < mandatory_questions_parent_onboarding.length; i++) {
      const section = getLocalStorage(`questionnaire_${i}`)
      if (section) {
        const parsedSection = JSON.parse(section)

        let count = 0

        if (parsedSection?.main_statement_answer) {
          count = count + 1
        }

        for (const statement of parsedSection?.sub_statements) {
          if (statement?.sub_statement_answer) count = count + 1
        }
        setAnswerObject((prev) => ({
          ...prev,
          [`questionnaire_${i}`]: count,
        }))
      }
    }
  }, [])

  useEffect(() => {
    setSelectedLanguage(searchParams.get('lng'))
    i18n.changeLanguage(searchParams.get('lng'))
  }, [searchParams])

  useEffect(() => {
    i18n.addResourceBundle('en', 'add_child', en)
    i18n.addResourceBundle('ar', 'add_child', ar)
  }, [])

  const theLastMainStatementIdInQuestionnaire = mandatory_questions_parent_onboarding?.length - 1
  const theLastSubStatementIdInQuestionnaire = mandatory_questions_parent_onboarding[theLastMainStatementIdInQuestionnaire]?.sub_statements?.length - 1

  useEffect(() => {
    if (!storage) {
      setLocalStorage(`questionnaire_${mainStatementId}`, JSON.stringify(mandatory_questions_parent_onboarding?.[mainStatementId]))
      setStorage(JSON.parse(getLocalStorage('questionnaire_0')))
    } else {
      let theLastAnsweredSection
      let theLastSubStatementWithoutAnswerInSection

      for (let i = 0; i < mandatory_questions_parent_onboarding.length; i++) {
        const localStorage = JSON.parse(getLocalStorage(`questionnaire_${i}`))
        if (localStorage) {
          if (localStorage.main_statement_answer === MAIN_STATEMENTS_ANSWERS?.[0]) {
            const theLastSubStatementWithoutAnswer = localStorage.sub_statements.find((statement) => !statement.sub_statement_answer)
            if (!theLastSubStatementWithoutAnswer) {
              continue
            } else {
              theLastAnsweredSection = localStorage
              theLastSubStatementWithoutAnswerInSection = theLastSubStatementWithoutAnswer
              break
            }
          } else {
            const theLastIdOfTheLastSection = mandatory_questions_parent_onboarding?.length - 1
            if (i === theLastIdOfTheLastSection) {
              setMainStatementId(theLastIdOfTheLastSection)
              if (localStorage[theLastIdOfTheLastSection]?.main_statement_answer === MAIN_STATEMENTS_ANSWERS?.[0]) {
                setTypeOfQuestion(TYPE_OF_QUESTION.SUB)
                setSubStatementId(localStorage?.sub_statements?.length - 1)
                return
              } else {
                setTypeOfQuestion(TYPE_OF_QUESTION.MAIN)
                setSubStatementId(localStorage?.sub_statements?.length - 1)
                return
              }
            } else {
              continue
            }
          }
        } else {
          theLastAnsweredSection = JSON.parse(getLocalStorage(`questionnaire_${i - 1}`))
          break
        }
      }
      if (!theLastAnsweredSection) {
        theLastAnsweredSection = JSON.parse(getLocalStorage(`questionnaire_${mandatory_questions_parent_onboarding.length - 1}`))
      }

      setMainStatementId(theLastAnsweredSection?.id)

      theLastSubStatementWithoutAnswerInSection = theLastAnsweredSection?.sub_statements.find((statement) => !statement?.sub_statement_answer)
      const theLastSubStatementIdInTheLastSection = theLastAnsweredSection?.sub_statements[theLastAnsweredSection?.sub_statements?.length - 1]

      if (theLastAnsweredSection?.main_statement_answer === MAIN_STATEMENTS_ANSWERS?.[0]) {
        setTypeOfQuestion(TYPE_OF_QUESTION.SUB)
        setSubStatementId(theLastSubStatementWithoutAnswerInSection?.id || theLastSubStatementIdInTheLastSection?.id)
      } else {
        setTypeOfQuestion(TYPE_OF_QUESTION.MAIN)
        setSubStatementId(0)
      }
    }
  }, [])

  useLayoutEffect(() => {
    setStorage(JSON.parse(getLocalStorage(`questionnaire_${mainStatementId}`)))
  }, [mainStatementId])

  useEffect(() => {
    if (storage) {
      let count = 0

      if (storage?.main_statement_answer) {
        count = count + 1
      }

      for (const statement of storage?.sub_statements) {
        if (statement?.sub_statement_answer) count = count + 1
      }
      setAnswerObject((prev) => ({
        ...prev,
        [`questionnaire_${mainStatementId}`]: count,
      }))
    }
  }, [storage, mainStatementId])

  useEffect(() => {
    let count = 0

    for (const key in answerObject) {
      count += answerObject[key]
    }

    setAmountOfAnsweredQuestions(count)
  }, [answerObject])

  const skipSection = () => {
    if (mainStatementId === 0) {
      setOnboardingStep(ONBOARDING_STEP.ADD_CHILD)
      return
    }

    const prevPart = JSON.parse(getLocalStorage(`questionnaire_${mainStatementId - 1}`))
    if (prevPart?.main_statement_answer === MAIN_STATEMENTS_ANSWERS?.[1]) {
      setTypeOfQuestion(TYPE_OF_QUESTION.MAIN)
    }
  }

  const handleSelectAnswer = (question, answer) => {
    if (typeOfQuestion === TYPE_OF_QUESTION.MAIN) {
      storage.main_statement_answer = answer

      if (answer === MAIN_STATEMENTS_ANSWERS?.[1]) {
        for (let i = 0; i < storage.sub_statements?.length; i++) {
          storage.sub_statements[i].sub_statement_answer = SKIPPED_VALUE
        }
      }

      setLocalStorage(`questionnaire_${mainStatementId}`, JSON.stringify(storage))
      setSubStatementId(0)

      let count = 0

      if (storage?.main_statement_answer) {
        count += 1
      }

      for (const statement of storage?.sub_statements || []) {
        if (statement?.sub_statement_answer) count += 1
      }

      setAnswerObject((prev) => ({
        ...prev,
        [`questionnaire_${mainStatementId}`]: count,
      }))

      if (mainStatementId === theLastMainStatementIdInQuestionnaire && answer === MAIN_STATEMENTS_ANSWERS?.[1]) {
        const mainLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
        mainLocalStorage.state = ONBOARDING_STEP.FAMILY_DYNAMICS_DISCLAIMER
        setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(mainLocalStorage))
        setOnboardingStep(ONBOARDING_STEP.FAMILY_DYNAMICS_DISCLAIMER)
        return
      }

      if (answer === MAIN_STATEMENTS_ANSWERS?.[1]) {
        const nextPart = JSON.parse(getLocalStorage(`questionnaire_${mainStatementId + 1}`))
        if (!nextPart?.main_statement_answer) {
          setLocalStorage(`questionnaire_${mainStatementId + 1}`, JSON.stringify(mandatory_questions_parent_onboarding[mainStatementId + 1]))
        }

        setTypeOfQuestion(TYPE_OF_QUESTION.MAIN)
        setMainStatementId((prev) => prev + 1)
      } else {
        setTypeOfQuestion(TYPE_OF_QUESTION.SUB)
      }
      return
    }

    if (typeOfQuestion === TYPE_OF_QUESTION.SUB) {
      const amountOfQuestionInCurrectSection = storage?.sub_statements?.length - 1

      storage.sub_statements[subStatementId].sub_statement_answer = answer
      setLocalStorage(`questionnaire_${mainStatementId}`, JSON.stringify(storage))

      let count = 0

      if (storage?.main_statement_answer) {
        count += 1
      }

      for (const statement of storage?.sub_statements || []) {
        if (statement?.sub_statement_answer) count += 1
      }

      setAnswerObject((prev) => ({
        ...prev,
        [`questionnaire_${mainStatementId}`]: count,
      }))

      if (subStatementId === amountOfQuestionInCurrectSection) {
        setSubStatementId(0)
        setMainStatementId((prev) => prev + 1)
        setTypeOfQuestion(TYPE_OF_QUESTION.MAIN)

        const nextPart = JSON.parse(getLocalStorage(`questionnaire_${mainStatementId + 1}`))
        if (!nextPart?.main_statement_answer && mandatory_questions_parent_onboarding[mainStatementId + 1]) {
          setLocalStorage(`questionnaire_${mainStatementId + 1}`, JSON.stringify(mandatory_questions_parent_onboarding[mainStatementId + 1]))
        }
      } else {
        setSubStatementId((prev) => prev + 1)
      }

      if (mainStatementId === theLastMainStatementIdInQuestionnaire && subStatementId === theLastSubStatementIdInQuestionnaire) {
        const mainLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
        mainLocalStorage.state = ONBOARDING_STEP.FAMILY_DYNAMICS_DISCLAIMER
        setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(mainLocalStorage))
        setOnboardingStep(ONBOARDING_STEP.FAMILY_DYNAMICS_DISCLAIMER)
        return
      }
    }
  }

  useEffect(() => {
    if (typeOfQuestion === TYPE_OF_QUESTION.MAIN) {
      setIsAnswered(storage?.main_statement_answer)
    } else {
      setIsAnswered(storage?.sub_statements?.[subStatementId]?.sub_statement_answer)
    }
  }, [subStatementId, typeOfQuestion, storage])

  const handleGoBack = () => {
    if (mainStatementId === 0 && typeOfQuestion === TYPE_OF_QUESTION.MAIN) {
      setOnboardingStep(ONBOARDING_STEP.ADD_CHILD)
      return
    }

    if (typeOfQuestion === TYPE_OF_QUESTION.SUB) {
      if (subStatementId === 0) {
        setTypeOfQuestion(TYPE_OF_QUESTION.MAIN)
      } else {
        setSubStatementId((prev) => prev - 1)
      }
    } else {
      const theLastSubStatementIdInPrevSection = mandatory_questions_parent_onboarding?.[mainStatementId - 1]?.sub_statements?.length - 1
      setMainStatementId((prev) => prev - 1)
      setTypeOfQuestion(TYPE_OF_QUESTION.SUB)
      setSubStatementId(theLastSubStatementIdInPrevSection)
      skipSection()
    }
  }

  const handleGoNext = () => {
    if (!isAnswered) {
      return
    }

    if (
      (mainStatementId === theLastMainStatementIdInQuestionnaire && subStatementId === theLastSubStatementIdInQuestionnaire) ||
      (mainStatementId === theLastMainStatementIdInQuestionnaire && storage?.main_statement_answer === MAIN_STATEMENTS_ANSWERS[1])
    ) {
      const mainLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
      mainLocalStorage.state = ONBOARDING_STEP.FAMILY_DYNAMICS_DISCLAIMER
      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(mainLocalStorage))
      setOnboardingStep(ONBOARDING_STEP.FAMILY_DYNAMICS_DISCLAIMER)
      return
    }

    if (typeOfQuestion === TYPE_OF_QUESTION.MAIN) {
      if (storage?.main_statement_answer === MAIN_STATEMENTS_ANSWERS?.[0]) {
        setSubStatementId(0)
        setTypeOfQuestion(TYPE_OF_QUESTION.SUB)
      } else {
        setTypeOfQuestion(TYPE_OF_QUESTION.MAIN)
        setMainStatementId((prev) => prev + 1)
      }
    } else {
      const theLastSubStatementIdInCurrentSection = storage.sub_statements.length - 1
      if (subStatementId === theLastSubStatementIdInCurrentSection) {
        setMainStatementId((prev) => prev + 1)
        setTypeOfQuestion(TYPE_OF_QUESTION.MAIN)
      } else {
        setSubStatementId((prev) => prev + 1)
      }
    }
  }

  return (
    <div className={Styles.main_container}>
      <div className={Styles.main_container__header} style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }}>
        <div onClick={handleGoBack} style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }} className={Styles.main_container__header__active}>
          {selectedLanguage === 'en' ? <ArrowLeftIcon /> : <ArrowRightIcon />}
          <p>{t('add_child:footer:back')}</p>
        </div>
        <div
          style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }}
          onClick={handleGoNext}
          className={isAnswered ? Styles.main_container__header__active : Styles.main_container__header__disabled}
        >
          <p>{t('add_child:footer:next')}</p>
          {selectedLanguage === 'en' ? <ArrowRightIcon /> : <ArrowLeftIcon />}
        </div>
      </div>
      <div className={Styles.main_container__content}>
        <div style={{ width: '100%' }} className={Styles.progress}>
          <LinearProgress variant="determinate" value={(amountOfAnsweredQuestions / totalQuestions) * 100} />
        </div>
        {storage?.id === mainStatementId && (
          <div className={Styles.main_container__content__item}>
            {typeOfQuestion === TYPE_OF_QUESTION.MAIN && <h4>{translateQuestionnaire?.[mainStatementId]?.[storage?.main_statement]?.[selectedLanguage]}</h4>}
            {typeOfQuestion === TYPE_OF_QUESTION.MAIN && (
              <div className={Styles.main_container__content__item__btns_main} style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }}>
                <div
                  onClick={() => handleSelectAnswer(storage, MAIN_STATEMENTS_ANSWERS?.[0])}
                  className={
                    isAnswered && storage?.main_statement_answer === MAIN_STATEMENTS_ANSWERS?.[0]
                      ? Styles.main_container__content__item__btns_main__active
                      : Styles.main_container__content__item__btns_main__default
                  }
                >
                  {translateMainAnswersQuestionnaire?.[selectedLanguage]?.[0]}
                </div>
                <div
                  onClick={() => handleSelectAnswer(storage, MAIN_STATEMENTS_ANSWERS?.[1])}
                  className={
                    isAnswered && storage?.main_statement_answer === MAIN_STATEMENTS_ANSWERS?.[1]
                      ? Styles.main_container__content__item__btns_main__active
                      : Styles.main_container__content__item__btns_main__default
                  }
                >
                  {translateMainAnswersQuestionnaire?.[selectedLanguage]?.[1]}
                </div>
              </div>
            )}
            {typeOfQuestion === TYPE_OF_QUESTION.SUB && (
              <h4>{translateQuestionnaire?.[mainStatementId]?.[storage?.sub_statements[subStatementId]?.statement]?.[selectedLanguage]}</h4>
            )}
            {typeOfQuestion === TYPE_OF_QUESTION.SUB && (
              <div className={Styles.main_container__content__item__btns_sub}>
                <div
                  onClick={() => handleSelectAnswer(storage, SUB_STATEMENTS_ANSWERS?.[0], storage?.sub_statements[subStatementId])}
                  className={
                    isAnswered && storage?.sub_statements[subStatementId]?.sub_statement_answer === SUB_STATEMENTS_ANSWERS?.[0]
                      ? Styles.main_container__content__item__btns_main__active
                      : Styles.main_container__content__item__btns_main__default
                  }
                >
                  {translateSubAnswersQuestionnaire?.[selectedLanguage]?.[0]}
                </div>
                <div
                  onClick={() => handleSelectAnswer(storage, SUB_STATEMENTS_ANSWERS?.[1], storage?.sub_statements[subStatementId])}
                  className={
                    isAnswered && storage?.sub_statements[subStatementId]?.sub_statement_answer === SUB_STATEMENTS_ANSWERS?.[1]
                      ? Styles.main_container__content__item__btns_main__active
                      : Styles.main_container__content__item__btns_main__default
                  }
                >
                  {translateSubAnswersQuestionnaire?.[selectedLanguage]?.[1]}
                </div>
                <div
                  onClick={() => handleSelectAnswer(storage, SUB_STATEMENTS_ANSWERS?.[2], storage?.sub_statements[subStatementId])}
                  className={
                    isAnswered && storage?.sub_statements[subStatementId]?.sub_statement_answer === SUB_STATEMENTS_ANSWERS?.[2]
                      ? Styles.main_container__content__item__btns_main__active
                      : Styles.main_container__content__item__btns_main__default
                  }
                >
                  {translateSubAnswersQuestionnaire?.[selectedLanguage]?.[2]}
                </div>
              </div>
            )}
          </div>
        )}
        {/* <div style={{ width: '100%' }} className={Styles.progress}>
          <LinearProgress variant="determinate" value={(amountOfAnsweredQuestions / totalQuestions) * 100} />
        </div> */}
      </div>
    </div>
  )
}
