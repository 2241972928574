// Actions
import { setLoading, setModalLoading } from '../reducers/generalSlice'
import {
  setChild,
  setAssignedTherapists,
  setHistoryRecords,
  setCurrentTherapist,
  setAssignedAdvisor,
  setAcceptedTherapists,
  setAllProfiles,
  setFreeConsultation,
  setProfileId,
} from '../reducers/parentSlice'
// Api call
import { parentApi } from '../api'
import { setHintModalDetails } from '../reducers/profileSlice'
import { notify } from 'helpers'

export const parentActions = {
  getTherapyInfo: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      parentApi
        .getTherapyInfo(data)
        .then((res) => {
          dispatch(setLoading(false))

          let lastSelectedProfile = localStorage.getItem('lastSelectedProfile')

          let profileIdForRequest

          if (lastSelectedProfile !== 'undefined') {
            lastSelectedProfile = JSON.parse(lastSelectedProfile)

            const selectedProfile = res.data?.find((item) => item?.profile?.id === lastSelectedProfile)

            if (selectedProfile) {
              dispatch(setChild(selectedProfile?.profile))
              dispatch(setAssignedTherapists(selectedProfile?.discovery))
              dispatch(setAssignedAdvisor(selectedProfile?.advisor))
              dispatch(setAcceptedTherapists(selectedProfile?.therapists))
              dispatch(setFreeConsultation(selectedProfile?.free_consultation))
              dispatch(
                setHintModalDetails({
                  onboarding_step: selectedProfile?.profile?.onboarding_step,
                  client_onboarding_step: selectedProfile?.profile?.onboarding_step,
                  isFirstView: selectedProfile?.profile?.onboarding_step === '0' ? true : false,
                }),
              )

              profileIdForRequest = selectedProfile?.profile?.id
            } else {
              dispatch(setChild(res?.data[0]?.profile))
              dispatch(setAssignedTherapists(res?.data[0]?.discovery))
              dispatch(setAssignedAdvisor(res?.data[0]?.advisor))
              dispatch(setAcceptedTherapists(res?.data[0]?.therapists))
              dispatch(setFreeConsultation(res?.data[0]?.free_consultation))
              dispatch(
                setHintModalDetails({
                  onboarding_step: res?.data[0]?.profile?.onboarding_step,
                  client_onboarding_step: res?.data[0]?.profile?.onboarding_step,
                  isFirstView: res?.data[0]?.profile?.onboarding_step === '0' ? true : false,
                }),
              )

              profileIdForRequest = res?.data[0]?.profile?.id

              localStorage.setItem('lastSelectedProfile', res?.data[0]?.profile?.id)
            }
          } else {
            dispatch(setChild(res?.data[0]?.profile))
            dispatch(setAssignedTherapists(res?.data[0]?.discovery))
            dispatch(setAssignedAdvisor(res?.data[0]?.advisor))
            dispatch(setAcceptedTherapists(res?.data[0]?.therapists))
            dispatch(setFreeConsultation(res?.data[0]?.free_consultation))
            dispatch(
              setHintModalDetails({
                onboarding_step: res?.data[0]?.profile?.onboarding_step,
                client_onboarding_step: res?.data[0]?.profile?.onboarding_step,
                isFirstView: res?.data[0]?.profile?.onboarding_step === '0' ? true : false,
              }),
            )

            profileIdForRequest = res?.data[0]?.profile?.id

            localStorage.setItem('lastSelectedProfile', res?.data[0]?.profile?.id)
          }

          dispatch(setAllProfiles(res.data))
          dispatch(setProfileId(profileIdForRequest))
        })
        .catch((error) => {
          console.log('parentApi.getTherapyInfo', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      console.log('parentActions.getTherapyInfo', error)
      dispatch(setLoading(false))
    }
  },
  getParentHistory: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      parentApi
        .getParentHistory(data)
        .then((res) => {
          dispatch(setHistoryRecords(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          console.log('parentApi.getParentHistory', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      console.log('parentActions.getParentHistory', error)
      dispatch(setLoading(false))
    }
  },
  getOneTherapist: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      parentApi
        .getOneTherapist(data)
        .then((res) => {
          dispatch(setCurrentTherapist(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          console.log('parentApi.getOneTherapist', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      console.log('parentActions.getOneTherapist', error)
      dispatch(setLoading(false))
    }
  },
  declineTherapist: (data) => (dispatch) => {
    dispatch(setModalLoading(true))

    return new Promise((resolve, reject) => {
      parentApi
        .declineTherapist(data)
        .then((res) => {
          dispatch(setModalLoading(false))
          notify(data?.notificationTranslations, false)
          resolve(res)
        })
        .catch((e) => {
          dispatch(setModalLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    })
  },
  acceptTherapist: (data) => (dispatch) => {
    dispatch(setLoading(true))

    return new Promise((resolve, reject) => {
      parentApi
        .acceptTherapist(data)
        .then((res) => {
          dispatch(setLoading(false))
          resolve(res)
        })
        .catch((e) => {
          dispatch(setLoading(false))
          dispatch(clearError())
          dispatch(setError('Something went wrong, please try again later!'))
        })
    })
  },
  getProfilesForChat: (data) => (dispatch) => {
    dispatch(setLoading(true))
    try {
      parentApi
        .getProfilesForChat(data)
        .then((res) => {
          dispatch(setAllProfiles(res.data))
          dispatch(setLoading(false))
        })
        .catch((error) => {
          console.log('parentApi.getOneTherapist', error)
          dispatch(setLoading(false))
        })
    } catch (error) {
      console.log('parentActions.getOneTherapist', error)
      dispatch(setLoading(false))
    }
  },
}
