import { useEffect, useState } from 'react'
import { ZoomMtg } from '@zoom/meetingsdk'
import { useSelector } from 'react-redux'
import { apiPath, meetingSdkKey, screeningType } from 'constants/index'
// import { ZoomExtraComponents } from './ZoomExtraComponents'
// import ReactDOM from 'react-dom'
import { Container, Text } from 'components/Core'
import { theme } from 'utils/theme'
import { useGetLanguage } from 'hooks/useGetLanguage'
import { notify } from 'helpers'
import { useNavigate } from 'react-router-dom'
import { useAfterDiscoveryCallMutation } from './service'

export function ZoomEmbedded() {
  const dataForJoin = {
    signature: '',
    sdkKey: meetingSdkKey,
    meetingNumber: '',
    password: '',
    userName: '',
    userEmail: '',
    zak: '',
    registrantToken: '',
  }
  const [therapistId, setTherapistId] = useState('')
  const [isJoinSuccess, seIsJoinSuccess] = useState(false)
  const [isDiscoveryCall, setIsDiscoveryCall] = useState(false)

  const navigate = useNavigate()

  const leaveUrl = process.env.REACT_ZOOM_LEAVE_URL
  const user = useSelector((state: any) => state.auth.user)

  const { isSelectedOpenZoomModal } = useSelector((state: any) => state.zoom)

  const getSignature = async () => {
    if (isSelectedOpenZoomModal && user?.token) {
      try {
        const authEndpoint = `${apiPath}/zoom/meetings/${isSelectedOpenZoomModal}/join/`
        const req = await fetch(authEndpoint, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${user?.token}`,
          },
        })
        if (req.ok) {
          const res = await req.json()
          dataForJoin.signature = res.signature
          dataForJoin.meetingNumber = res.meeting_number
          dataForJoin.userName = res.username
          dataForJoin.userEmail = res.user_email

          setTherapistId(res?.therapist_id)

          if (res.screening_type === screeningType.discovery) {
            setIsDiscoveryCall(true)
          }

          startMeeting()
        } else {
          const errorResponse = await req.json()
          notify(errorResponse?.error, true)
          setTimeout(() => {
            navigate('/')
            window.location.reload()
          }, 3000)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  function startMeeting() {
    document.getElementById('zmmtg-root')!.style.display = 'block'

    ZoomMtg.init({
      leaveUrl: leaveUrl as string,
      patchJsMedia: true,
      leaveOnPageUnload: true,
      screenShare: true,
      success: (success: unknown) => {
        ZoomMtg.join({
          signature: dataForJoin.signature,
          sdkKey: dataForJoin.sdkKey,
          meetingNumber: dataForJoin.meetingNumber,
          passWord: dataForJoin.password,
          userName: dataForJoin.userName,
          userEmail: dataForJoin.userEmail,
          tk: dataForJoin.registrantToken,
          zak: dataForJoin.zak,

          success: (success: unknown) => {
            seIsJoinSuccess(true)
            // const zoomHolder = document.getElementById('zmmtg-root')
            // if (zoomHolder) {
            //   const buttonContainer = document.createElement('div')
            //   buttonContainer.style.position = 'absolute'
            //   buttonContainer.style.top = '10px'
            //   buttonContainer.style.left = '10px'
            //   // buttonContainer.style.zIndex = '999'
            //   zoomHolder.appendChild(buttonContainer)
            //   ReactDOM.render(<ZoomExtraComponents />, buttonContainer)
            // }
          },
          error: (error: unknown) => {
            console.log(error)
          },
        })
      },
      error: (error: unknown) => {
        console.log(error)
      },
    })
  }

  useEffect(() => {
    if (isSelectedOpenZoomModal && user?.token) {
      getSignature()
    }
  }, [isSelectedOpenZoomModal, user?.token])

  useEffect(() => {
    ZoomMtg.preLoadWasm()
    ZoomMtg.prepareWebSDK()
  }, [])

  const [sendTrigger] = useAfterDiscoveryCallMutation()

  useEffect(() => {
    ZoomMtg.inMeetingServiceListener('onUserLeave', () => {
      const profileId = localStorage.getItem('lastSelectedProfile')
      const token = user?.token
      if (isJoinSuccess && profileId && token && isDiscoveryCall) {
        sendTrigger({
          profile_id: profileId,
          therapist_id: therapistId,
          token,
        })
      }
    })
  }, [isJoinSuccess, therapistId, isDiscoveryCall, user?.token])

  const { isArabic } = useGetLanguage(user.preferred_language, 'parent')
  return (
    <Container bg={theme.colors.black[100]} className="App" height="100%" width="100%">
      <Text style={{ direction: isArabic ? 'rtl' : 'ltr' }} color={theme.colors.black[900]}>
        {isArabic ? 'جاري تحضير جلستك...' : 'Preparing your session...'}
      </Text>
    </Container>
  )
}
