//MUI
import { CircularProgress, Modal } from '@mui/material'
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as CloseIcon } from '../../../../theme/assets/icons/close.svg'
import { ReactComponent as FileIcon } from '../../../../theme/assets/icons/file_new.svg'
//Core
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect, useMemo } from 'react'
//Utils
import { getInitials, returnCountry, convertBytes, currencyFormatter } from '../../../../utils'
import { useTranslation } from 'react-i18next'
//Components
import { DocumentModal } from '../../../GeneralComponents'
//Actions
import { parentActions, therapistActions } from '../../../../actions'

import en from './locales/en.json'
import ar from './locales/ar.json'
import { useGetLanguage } from '../../../../hooks/useGetLanguage'
import { CenterRow, Container, Flex, Text } from 'components/Core'
import { useGetSessionLengthPriceMutation } from 'components/ParentFlow/Therapy/service'

/**
 * TherapistInfoModal component for displaying therapist information.
 * @param {object} props - Component props.
 * @param {boolean} props.open - Modal open state.
 * @param {Function} props.onClose - Function to close the modal.
 * @param {string} props.userType - Type of user ('parent' or 'therapist').
 * @param {string} props.therapistId - ID of the therapist.
 * @returns {JSX.Element} TherapistInfoModal component JSX.
 */
export const TherapistInfoModal = ({ open, onClose, userType, therapistId }) => {
  const dispatch = useDispatch()

  /**
   * User data fetched from Redux store.
   * @type {object}
   */
  const user = useSelector((state) => state.auth.user)
  const { language } = useGetLanguage(user?.user?.preferred_language, user?.user?.user_type)

  /**
   * Therapist data fetched from Redux store.
   * @type {object}
   */
  const therapist = useSelector((state) => state.parent.currentTherapist)

  /**
   * Therapist documents fetched from Redux store.
   * @type {Array}
   */
  const documents = useSelector((state) => state.therapist.documents)

  /**
   * State for managing visibility of document preview modal.
   * @type {[boolean, function]}
   */
  const [isOpenDocumentPreview, setIsOpenDocumentPreview] = useState(false)

  /**
   * State for storing the path of the selected document to preview.
   * @type {[string, function]}
   */
  const [selectedDocumentPath, setSelectedDocumentPath] = useState('')

  /**
   * Effect to fetch therapist and documents data based on therapistId.
   */
  useEffect(() => {
    const data = {
      token: user?.token,
      id: therapistId,
    }

    if (user?.user?.user_type === 'parent') Object.assign(data, { profile_id: localStorage.getItem('lastSelectedProfile') })
    dispatch(parentActions.getOneTherapist(data))
    dispatch(therapistActions.getDocumentsForAdmins(data))
  }, [therapistId, user])

  /**
   * Opens the document preview modal with the selected document.
   * @param {string} documentPath - Path of the document to preview.
   */
  const handleOpenDocumentPreview = (documentPath) => {
    setSelectedDocumentPath(documentPath)
    setIsOpenDocumentPreview(true)
  }

  /**
   * Closes the document preview modal.
   */
  const handleCloseDocumentPreview = () => setIsOpenDocumentPreview(false)

  /**
   * Returns the degree level description based on abbreviation.
   * @param {string} degree - Abbreviation of the degree level.
   * @returns {string} Full description of the degree level.
   */
  const returnDegree = (degree) => {
    switch (degree) {
      case 'ml':
        return 'Masters Level'
      case 'bl':
        return 'Bachelors Level'
      case 'dl':
        return 'Doctoral Level'
      default:
        return '-'
    }
  }

  /**
   * Returns the clinical services status description based on abbreviation.
   * @param {string} clinicalServices - Abbreviation of the clinical services status.
   * @returns {string} Full description of the clinical services status.
   */
  const returnClinicalServices = (clinicalServices) => {
    switch (clinicalServices) {
      case 'pt':
        return 'Yes, part time'
      case 'ft':
        return 'Yes, full time'
      case 'na':
        return 'No, I am not active clinically'
      default:
        return '-'
    }
  }

  /**
   * Returns sorted documents grouped by type (personal, professional, educational).
   * @returns {object} Object containing arrays of sorted documents.
   */
  const returnSortedDocuments = () => {
    const sortedDocuments = {
      personal: [],
      professional: [],
      educational: [],
    }
    documents?.map((document) => {
      if (document.file_type === 'personal') {
        sortedDocuments.personal.push(document)
      } else if (document.file_type === 'professional') {
        sortedDocuments.professional.push(document)
      } else {
        sortedDocuments.educational.push(document)
      }
    })

    return sortedDocuments
  }

  const isArabic = useMemo(() => language === 'ar', [language])
  const direction = useMemo(() => (isArabic ? 'rtl' : 'ltr'), [isArabic])

  /**Initialize translation */
  const { t, i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  useEffect(() => {
    i18n.addResourceBundle('en', 'therapistModal', en)
    i18n.addResourceBundle('ar', 'therapistModal', ar)
  }, [])

  const [getSessionLengthPrice, { isLoading, data }] = useGetSessionLengthPriceMutation()
  useEffect(() => {
    getSessionLengthPrice({ therapist_id: therapistId, token: user?.token })
  }, [])

  return (
    <Modal open={open} onClose={onClose} className={Styles.modal}>
      <div className={Styles.main_container}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-end' }}>
          <CloseIcon className={Styles.header__icon} onClick={onClose} />
        </div>

        <div style={{ direction }} className={Styles.container_scroll}>
          <div className={Styles.main_info}>
            <div className={Styles.main_info__photo}>
              {therapist?.user?.profile_pic ? <img src={therapist?.user?.profile_pic} alt="therapist_photo" /> : getInitials(therapist?.user?.full_name)}
            </div>
            <div style={{ gap: 10 }} className={Styles.main_info__information}>
              <h5 className={Styles.main_info__title}>{therapist?.user?.full_name}</h5>
              <p>{therapist?.professional_qualities?.join(', ')}</p>
              <div>
                <p style={{ fontWeight: 'bold' }}>{t('therapistModal:bio')}</p>
                <p style={{ opacity: therapist.bio ? 1 : 0.5 }} className={Styles.main_info__information__about_me}>
                  {therapist.bio || t('therapistModal:bioIsEmpty')}
                </p>
              </div>
            </div>
          </div>
          {userType !== 'parent' && (
            <>
              <div className={Styles.personal_info}>
                <div className={Styles.personal_info__content}>
                  <div className={Styles.personal_info__content__item}>
                    <span>{t('therapistModal:country')}</span>
                    <>{returnCountry(therapist?.user?.country, Styles) || '-'}</>
                  </div>
                  <div className={Styles.personal_info__content__item}>
                    <span>{t('therapistModal:timezone')}</span>
                    <p>{therapist?.user?.time_zone || '-'}</p>
                  </div>
                  <div className={Styles.personal_info__content__item}>
                    <span>{t('therapistModal:nationality')}</span>
                    <p>{therapist?.nationality || '-'}</p>
                  </div>
                  <div className={Styles.personal_info__content__item}>
                    <span>{t('therapistModal:language')}</span>
                    <p>{therapist?.languages?.join(', ') || '-'}</p>
                  </div>
                  <div className={Styles.personal_info__content__item}>
                    <span>{t('therapistModal:age')}</span>
                    <p>{therapist?.age || '-'}</p>
                  </div>
                </div>
              </div>
              <div className={Styles.professional_info}>
                <h6>{t('therapistModal:professionalInfo')}</h6>
                <div className={Styles.professional_info__content}>
                  <div className={Styles.professional_info__content__item}>
                    <span>{t('therapistModal:priceForRegularSession')}</span>
                    <p>{`$${therapist?.session_price}` || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>{t('therapistModal:yearsOfExperience')}</span>
                    <p>{therapist?.experience || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>{t('therapistModal:educationalDegree')}</span>
                    <p>{returnDegree(therapist?.education_level) || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>{t('therapistModal:licensing')}</span>
                    <p>{therapist?.licence?.join(', ') || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>{t('therapistModal:areasOfExpertise')}</span>
                    <p>{therapist?.expertise?.join(', ') || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>{t('therapistModal:advantageOfJoiningNumuw')}</span>
                    <p>{therapist?.job_advantages || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>{t('therapistModal:facilityCurrentlyProvidingService')}</span>
                    <p>{therapist?.clinical_services_provided?.join('; ') || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>{t('therapistModal:doYouCurrentlyProvideServices')}</span>
                    <p>{returnClinicalServices(therapist?.current_status) || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>{t('therapistModal:whichAgeGroupsWorkingOn')}</span>
                    <p>{therapist?.age_groups_served || '-'}</p>
                  </div>
                </div>
              </div>
              <div className={Styles.documents_info}>
                <h6>{t('therapistModal:professionalQualification')}</h6>
                {returnSortedDocuments().personal.length !== 0 && (
                  <div className={Styles.documents_info__section}>
                    <p className={Styles.headline_files}>{t('therapistModal:personal')}</p>
                    <div className={Styles.documents_info__section__documents}>
                      {returnSortedDocuments().personal?.map((document) => {
                        return (
                          <div key={document.id} onClick={() => handleOpenDocumentPreview(document.file)} className={Styles.document_container}>
                            <div className={Styles.preview}>
                              <FileIcon />
                            </div>
                            <div className={Styles.text}>
                              <p className={Styles.name}>{document.filename}</p>
                              <span>({convertBytes(document.size)})</span>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
                {returnSortedDocuments().professional.length !== 0 && (
                  <div className={Styles.documents_info__section}>
                    <p className={Styles.headline_files}>{t('therapistModal:professional')}</p>
                    <div className={Styles.documents_info__section__documents}>
                      {returnSortedDocuments().professional?.map((document) => {
                        return (
                          <div key={document.id} onClick={() => handleOpenDocumentPreview(document.file)} className={Styles.document_container}>
                            <div className={Styles.preview}>
                              <FileIcon />
                            </div>
                            <div className={Styles.text}>
                              <p className={Styles.name}>{document.filename}</p>
                              <span>({convertBytes(document.size)})</span>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
                {returnSortedDocuments().educational.length !== 0 && (
                  <div className={Styles.documents_info__section}>
                    <p className={Styles.headline_files}>{t('therapistModal:educational')}</p>
                    <div className={Styles.documents_info__section__documents}>
                      {returnSortedDocuments().educational?.map((document) => {
                        return (
                          <div key={document.id} onClick={() => handleOpenDocumentPreview(document.file)} className={Styles.document_container}>
                            <div className={Styles.preview}>
                              <FileIcon />
                            </div>
                            <div className={Styles.text}>
                              <p className={Styles.name}>{document.filename}</p>
                              <span>({convertBytes(document.size)})</span>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {userType === 'parent' && (
            <>
              <div className={Styles.professional_info}>
                <div className={Styles.professional_info__content}>
                  <div className={Styles.professional_info__content__item}>
                    <span>{t('therapistModal:priceForRegularSession')}</span>
                    {/* <p>{therapist?.session_price === 0 ? 'Free' : `$${therapist?.session_price}`}</p> */}
                    {/* <Container width="100%" flexDirection="column"> */}

                    <CenterRow flexWrap="wrap" gap="10px">
                      {!isLoading ? (
                        data?.data?.map((item) => (
                          <Container pb={2} borderBottom={1}>
                            <Flex gap="5px" width={['100%', '100%', '300px']} alignItems="flex-end">
                              <h6>{item.duration}</h6>
                              <p width="auto" fontSize={16} variant="body5" color="inherit">
                                {t('recording_modal:minutes')}
                              </p>
                            </Flex>
                            <p>{currencyFormatter(item.price)}</p>
                          </Container>
                        ))
                      ) : (
                        <CircularProgress size={30} />
                      )}
                    </CenterRow>
                    {/* </Container> */}
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>{t('therapistModal:yearsOfExperience')}</span>
                    <p>{therapist?.experience || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>{t('therapistModal:language')}</span>
                    <p>{therapist?.languages?.join(', ') || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>{t('therapistModal:educationalDegree')}</span>
                    <p>{returnDegree(therapist?.education_level) || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>{t('therapistModal:licensing')}</span>
                    <p>{therapist?.licence || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>{t('therapistModal:areasOfExpertise')}</span>
                    <p>{therapist?.expertise?.join(', ') || '-'}</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {isOpenDocumentPreview && <DocumentModal open={isOpenDocumentPreview} onClose={handleCloseDocumentPreview} document={selectedDocumentPath} />}
      </div>
    </Modal>
  )
}
