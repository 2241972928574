//Core
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
//Styles
import Styles from './styles.module.scss'
//MUI
import { Modal, ToggleButtonGroup, ToggleButton } from '@mui/material'
//Images
import { ReactComponent as CloseIcon } from '../../../../theme/assets/icons/close_updated.svg'
//Components
import { CalComIntegration } from '../../../GeneralComponents'

const AddProfilePopup = ({ open, onClose, isAdultHasProfile }) => {
  const navigate = useNavigate()
  const user = useSelector((state) => state.auth.user)
  const [calComLink, setCalComLink] = useState('')

  const [profileOption, setProfileOption] = useState('')
  const [isOpenCalcomPopup, setIsOpenCalcomPopup] = useState(false)
  const [searchParams] = useSearchParams()

  const handleOpenAddProfileCalcomPopup = useCallback(() => {
    if (profileOption === 'adult') {
      setIsOpenCalcomPopup(true)
      setCalComLink(process.env.REACT_APP_CAL_COM_LINK_PARENT)
    }

    if (profileOption === 'child') {
      navigate(`add_questionnaire?lng=${searchParams.get('lng') ?? 'en'}`)
      onClose()
    }
  }, [profileOption, searchParams.get('lng')])

  const handleCloseAddProfileCalcomPopup = () => {
    setIsOpenCalcomPopup(false)
    onClose()
  }

  const handleChange = (_, newProfileOption) => {
    setProfileOption(newProfileOption)
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className={Styles.profile_container}>
        <h2 className={Styles.profile_title}>New profile</h2>
        <CloseIcon className={Styles.profile_close_icon} onClick={onClose} />
        <p className={Styles.profile_question}>Do you want to create a new profile for?</p>
        <div className={Styles.profile_buttons}>
          <ToggleButtonGroup color="secondary" exclusive value={profileOption} onChange={handleChange}>
            <ToggleButton sx={{ marginRight: '10px' }} disabled={isAdultHasProfile} value="adult">
              Myself
            </ToggleButton>
            <ToggleButton value="child">Child</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className={Styles.profile_next_block}>
          <div onClick={handleOpenAddProfileCalcomPopup} className={Styles.profile_next}>
            Next
          </div>
        </div>
        {isOpenCalcomPopup && (
          <CalComIntegration userInfo={user?.user ?? user} open={isOpenCalcomPopup} onClose={handleCloseAddProfileCalcomPopup} link={calComLink} isRegularCall={false} />
        )}
      </div>
    </Modal>
  )
}

export default AddProfilePopup
