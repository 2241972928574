import { apiRoutes } from 'apiRoutes'
import { rootServiceApi } from 'store/service'

export const checkEmailExistenceServiceForAuthenticatedUser = rootServiceApi.injectEndpoints({
  endpoints: (build) => ({
    feedBack: build.mutation({
      query: ({ body, token }) => {
        return {
          url: apiRoutes.submitFeedback,
          method: 'POST',
          body,
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      },
    }),
  }),
})

export const { useFeedBackMutation } = checkEmailExistenceServiceForAuthenticatedUser
