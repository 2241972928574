import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'store/type'

const rootState = (state: RootState) => state

export const selectDefaultLayout = createSelector([rootState], (state) => state.defaultLayout)

export const selectAuthState = createSelector([rootState], (state) => state?.auth)
export const selectUser = createSelector([selectAuthState], (state) => state?.user)

export const selectAlertState = createSelector([selectDefaultLayout], (state) => state.alert)
