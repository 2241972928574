// Core
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//Styles
import Styles from './styles.module.scss'
// Actions
import { therapistActions, zoomActions } from '../../../actions'
import { setIsOpenZoomModal, setIsSelectedOpenZoomModal } from '../../../reducers/zoomSlice'
//Components
import { Table } from '../../Applies/Table'
import { Spinner } from '../../ChatSystem/Common/Spinner'
import { ConfirmationPopup, CustomPagination, NoDataComponent, Notifications } from '../../GeneralComponents'
import { ChildInfoPopup } from '../Popups/ChildInfo'
//Icons
import { ReactComponent as SearchIcon } from '../../../theme/assets/icons/search_convex.svg'
//MUI
import { InputBase } from '@mui/material'
//Utils
import { paginateArray } from '../../../utils'
import { useNavigate } from 'react-router-dom'
import { therapistNotificationTranslations } from 'data/locale.data'
import { useAlertController } from 'components/AlertMessage/useAlertController'
import { useTranslation } from 'react-i18next'
import { notify } from 'helpers'

/**
 * TherapistApplications component for managing therapist applications.
 * @returns {JSX.Element} TherapistApplications component.
 */
export const TherapistApplications = () => {
  const dispatch = useDispatch()

  /**
   * Redux state
   */
  const user = useSelector((state) => state.auth.user)
  const allApplications = useSelector((state) => state.therapist.allApplications)
  const loading = useSelector((state) => state.general.loading)

  /**
   * Component state
   */
  const [isOpenDeclineChildPopup, setIsOpenDeclineChildPopup] = useState(false)
  const [isOpenChildDetails, setIsOpenChildDetails] = useState(false)
  const [isAdult, setIsAdult] = useState(false)

  const [applications, setApplications] = useState([])
  const [selectedApplicationId, setSelectedApplicationId] = useState(undefined)

  const [refetch, setRefetch] = useState(false)
  const [selectedTab, setSelectedTab] = useState('all')

  const [currentPage, setCurrentPage] = useState(1)
  const [dataForPagination, setDataForPagination] = useState([])

  const headers = [
    { text: 'DATE CREATED', sorting: true },
    { text: 'NAME OF APPLIER', sorting: true },
    { text: 'PROBLEM', sorting: true },
    { text: 'BIRTHDAY', sorting: false },
    { text: 'STATUS', sorting: true },
    { text: 'MEETING TIME', sorting: false },
    { text: 'ACTION', sorting: false },
  ]

  /**
   * Effect to fetch all applications on initial load and when refetch state changes.
   */
  useEffect(() => {
    const data = {
      token: user.token,
    }
    dispatch(therapistActions.getAllApplications(data))
  }, [refetch, user])

  /**
   * Effect to paginate applications when allApplications state changes.
   */
  useEffect(() => {
    const paginatedData = paginateArray(allApplications)
    if (paginatedData.length) {
      setApplications([...(paginatedData[currentPage - 1] || 0)])
    } else {
      setApplications([])
    }
  }, [allApplications])

  /**
   * Effect to filter and paginate applications based on selectedTab and currentPage.
   */
  useEffect(() => {
    if (selectedTab === 'all') {
      const paginatedData = paginateArray(allApplications)
      setDataForPagination(allApplications)
      if (paginatedData.length) {
        setApplications([...(paginatedData[currentPage - 1] || 0)])
      } else {
        setApplications([])
      }
      return
    }

    const sortedData = allApplications.filter((application) => application.therapist_status === selectedTab)
    const paginatedData = paginateArray(sortedData)
    if (paginatedData.length) {
      setDataForPagination(sortedData)
      setApplications([...(paginatedData[currentPage - 1] || 0)])
    } else {
      setDataForPagination(sortedData)
      setApplications([])
    }
  }, [selectedTab, currentPage])

  /**
   * Accepts a child's application and triggers notifications.
   * @param {string} screeningId - The ID of the screening application.
   */
  const handleAcceptChild = (screeningId) => {
    const data = {
      token: user?.token,
      id: screeningId,
      action: () => setRefetch((prev) => !prev),
      notification: therapistNotificationTranslations?.accepted?.en,
    }
    dispatch(therapistActions.acceptApplication(data))
  }

  /**
   * Opens the decline child confirmation popup.
   * @param {string} screeningId - The ID of the screening application.
   */
  const handleOpenDeclineChildPopup = (screeningId) => {
    setSelectedApplicationId(screeningId)
    setIsOpenDeclineChildPopup(true)
  }

  /**
   * Declines a child's application.
   * @param {object} data - Data containing decline reason and application ID.
   */
  const declineApplication = (data) => {
    const dataForDecline = {
      id: selectedApplicationId,
      token: user.token,
      payload: {
        decline_reason: data?.reason,
      },
      notification: therapistNotificationTranslations?.dicline?.en,
      action: () => {
        setIsOpenDeclineChildPopup(false)
        setRefetch((prev) => !prev)
      },
    }
    // console.log(dataForDecline)
    dispatch(therapistActions.declineApplication(dataForDecline))
  }

  /**
   * Opens child details popup.
   * @param {string} screeningId - The ID of the screening application.
   * @param {boolean} isAdult - Flag indicating if the applicant is an adult.
   */
  const handleOpenChildDetails = (screeningId, isAdult) => {
    setSelectedApplicationId(screeningId)
    setIsOpenChildDetails(true)
    setIsAdult(isAdult)
  }

  /**
   * Closes child details popup.
   */
  const handleCloseChildDetails = () => {
    setIsOpenChildDetails(false)
  }

  /**
   * Content configuration for decline application popup.
   */
  const contentForDeclineApplicationPopup = {
    title: 'Are you sure you want to decline the apply?',
    secondaryText: {
      text: 'What are the reasons for declining?',
      style: Styles.popup_text,
    },
    buttons: {
      direction: 'vertical',
      content: [
        {
          id: 1,
          text: 'Continue',
          style: Styles.btn_decline_application__delete,
          onClick: declineApplication,
        },
        {
          id: 2,
          text: 'Cancel',
          style: Styles.btn_decline_application__cancel,
          onClick: () => setIsOpenDeclineChildPopup(false),
        },
      ],
    },
  }
  const navigate = useNavigate()
  const { setAlert } = useAlertController()
  const { t } = useTranslation()
  /**
   * Handles joining a Zoom meeting.
   * @param {string} zoomMeetingId - The ID of the Zoom meeting.
   */
  const handleJoinMeeting = (zoomMeetingId) => {
    const dataForZoom = {
      id: zoomMeetingId,
      token: user.token,
    }

    dispatch(zoomActions.getMeetingInformation(dataForZoom))
    dispatch(setIsOpenZoomModal())
    if (zoomMeetingId) {
      dispatch(setIsSelectedOpenZoomModal(zoomMeetingId))
      navigate('/session')
    } else {
      notify(t('alertMessages:zoomMeetingNotFound'), true, false)
    }
  }

  /**
   * Click event handlers for various actions.
   */
  const onClickEvents = {
    acceptApplication: handleAcceptChild,
    declineApplication: handleOpenDeclineChildPopup,
    openUserDetails: handleOpenChildDetails,
    joinMeeting: handleJoinMeeting,
  }

  /**
   * Handles selecting a tab.
   * @param {string} tabName - The name of the selected tab.
   */
  const handleSelectTab = (tabName) => {
    setSelectedTab(tabName)
  }

  /**
   * Handles input change for search.
   * @param {Object} e - The input change event.
   */
  const handleChangeInput = (e) => {
    const inputValue = e.target.value.toLowerCase()

    const filteredArray = allApplications.filter((application) => application.profile_name.toLowerCase().includes(inputValue))
    setApplications(filteredArray)
  }

  return (
    <div className={Styles.main_container}>
      <div className={Styles.header}>
        <div className={Styles.header__first_container}>
          <h6>Applications</h6>
          <div className={Styles.header__search}>
            <div className={Styles.header__search__container}>
              <SearchIcon />
              <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search by name" onChange={(e) => handleChangeInput(e)} className={Styles.header__search__container__text} />
            </div>
          </div>
        </div>
        <div className={Styles.tabs}>
          <p className={selectedTab === 'all' && Styles.tabs__selected_tab} onClick={() => handleSelectTab('all')}>
            All
          </p>
          <p className={selectedTab === 'waiting' && Styles.tabs__selected_tab} onClick={() => handleSelectTab('waiting')}>
            Waiting for assignment
          </p>
          <p className={selectedTab === 'accepted' && Styles.tabs__selected_tab} onClick={() => handleSelectTab('accepted')}>
            Accepted
          </p>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : allApplications.length ? (
        <div className={Styles.data_container}>
          <Table headers={headers} data={applications} refetch={setRefetch} userType={user?.user?.user_type} userDataType={'application'} onClickEvents={onClickEvents} />
        </div>
      ) : (
        <NoDataComponent text={'There are no applications yet.'} />
      )}
      <CustomPagination dataArray={dataForPagination} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      {isOpenDeclineChildPopup && (
        <ConfirmationPopup
          open={isOpenDeclineChildPopup}
          onClose={() => setIsOpenDeclineChildPopup(false)}
          content={contentForDeclineApplicationPopup}
          width={'436px'}
          isDeclinePopup={true}
        />
      )}
      {isOpenChildDetails && (
        <ChildInfoPopup open={isOpenChildDetails} onClose={handleCloseChildDetails} screeningId={selectedApplicationId} isTherapist={true} isAdult={isAdult} />
      )}
    </div>
  )
}
