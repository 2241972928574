import type { AlertState, DefaultLayoutState } from 'defaultLayout/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

const initialState: DefaultLayoutState = {
  alert: {
    open: false,
    message: '',
    type: 'info',
  },
}

export const defaultLayoutSlice = createSlice({
  name: 'defaultLayout',
  initialState,
  reducers: {
    setAlert: (state, { payload }: PayloadAction<Partial<Omit<AlertState, 'open'>>>) => {
      state.alert = { ...state.alert, ...payload, open: true }
    },
    closeAlert: (state) => {
      state.alert = { ...initialState.alert }
    },
  },
})

export const useDefaultLayoutSlice = () => {
  const dispatch = useDispatch()
  const { actions } = defaultLayoutSlice
  return { actions, dispatch }
}

export const defaultLayoutReducer = defaultLayoutSlice.reducer
