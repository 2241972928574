import { userTypes } from 'constants/index'
import { useEffect, useState } from 'react'

export const useGetLanguage = (userPreferredLanguage: string, userType: keyof typeof userTypes) => {
  const [language, setLanguage] = useState('')
  const localStorageLanguage = localStorage.getItem('language')
  useEffect(() => {
    if (localStorageLanguage && userType === 'parent') {
      setLanguage(localStorageLanguage)
    } else {
      setLanguage(userPreferredLanguage ?? 'en')
    }
  }, [localStorageLanguage, userPreferredLanguage, userType])
  const isArabic = language === 'ar'
  return { language, isArabic }
}
