//Core
import { useEffect, useState } from 'react'
//Styles
import Styles from './styles.module.scss'
//MUI
import { Modal } from '@mui/material'
//CalCom
import Cal, { getCalApi } from '@calcom/embed-react'
//Icon
import { ReactComponent as CloseIcon } from '../../../theme/assets/icons/close.svg'
import { ReactComponent as ThanksIcon } from '../../../theme/assets/images/thanks.svg'
import { useSelector } from 'react-redux'
import { selectCalComDuration } from './slice/selectors'

export const CalComIntegration = ({ open, onClose, link, userInfo, isRegularCall, metadata }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const duration = useSelector(selectCalComDuration)
  console.log({ duration })

  useEffect(() => {
    ;(async function () {
      const cal = await getCalApi()
      cal('ui', { theme: 'light', styles: { branding: { brandColor: '#D89B95' } }, hideEventTypeDetails: false, layout: 'month_view' })
      cal('on', {
        action: 'bookingSuccessful',
        callback: (e) => {
          e.stopPropagation()
          setIsSuccess(true)
        },
      })
    })()
  }, [])

  return (
    <Modal open={open} onClose={onClose} className={Styles.modal}>
      <div className={Styles.main_container}>
        <div className={Styles.header}>
          <CloseIcon className={Styles.header__icon} onClick={onClose} />
        </div>
        {isSuccess ? (
          <>
            <div className={Styles.thanks_section}>
              <ThanksIcon />
              <div className={Styles.thanks_section__text}>
                <h3 className={Styles.thanks_title}>Thank you!</h3>
                {isRegularCall ? (
                  <p className={Styles.thanks_desc}>
                    You have successfully reserved a call. Reservation takes only 15 minutes. Please go to the Therapy page and in your History pay for reserved call.
                  </p>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column' }} className={Styles.thanks_desc}>
                    Thank you for scheduling a consultation!
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <Cal
              calLink={link}
              style={{ width: '100%', height: '100%', overflow: 'scroll' }}
              config={{
                layout: 'month_view',
                name: userInfo?.user?.full_name || userInfo?.full_name || '',
                email: userInfo?.user?.email || userInfo?.email || '',
                'db-email': userInfo?.user?.email || userInfo?.email || '',
                'profile-id': userInfo?.id || '',
                'phone-number': userInfo?.user?.phone_number || '',
                'recording-consent-metadata': JSON.stringify(metadata || {}),
                duration,
              }}
              calOrigin="https://numuw.cal.com"
            />
          </>
        )}
      </div>
    </Modal>
  )
}
