import { Alert, IconButton } from '@mui/material'
import { useAlertController } from './useAlertController'
import { CenterRow, Flex, Text } from 'components/Core'
import { Close } from '@mui/icons-material'
import { useEffect } from 'react'

export const AlertMessage = () => {
  const { message, type, open, closeAlert } = useAlertController()

  useEffect(() => {
    const timeout = setTimeout(() => {
      closeAlert()
    }, 6000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])
  return open ? (
    <Alert sx={{ width: '100%', position: 'relative' }} severity={type}>
      <CenterRow width="100%" justifyContent={['space-between']}>
        <Text pr={['20px']}>{message}</Text>
        <Flex alignItems={'center'} right={2} top={0} bottom={0} my="auto" position="absolute">
          <IconButton>
            <Close onClick={() => closeAlert()} />
          </IconButton>
        </Flex>
      </CenterRow>
    </Alert>
  ) : null
}
