//Core
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
//Styles
import Styles from './styles.module.scss'
//Data
import { childOnboardingQuestions, adultOnboardingQuestions } from '../../../data'
// Actions
import { advisorOnbordingActions } from '../../../actions'
//Icons
import { ReactComponent as ArrowLeft } from '../../../theme/assets/icons/arrow_left_icon.svg'
import { ReactComponent as EditIcon } from '../../../theme/assets/icons/edit_icon.svg'
//Components
import { Spinner } from '../../ChatSystem/Common/Spinner'
import { ConfirmationPopup } from '../../GeneralComponents'
//Utils
import { returnLanguagesFromArray } from '../../../utils'
import { selectOnboarding } from '../Questions/selectors'

/**
 * Summary component displays a summary of the onboarding process including profile details, guided onboarding questions,
 * and provides options to edit information, submit or decline the application.
 *
 * @param {Object} props - The props object.
 * @param {boolean} props.isModal - Determines if the summary is displayed in a modal.
 * @param {boolean} props.isAdult - Indicates if the profile type is adult.
 * @returns {JSX.Element} - The rendered Summary component.
 */

export const Summary = ({ isModal, isAdult }) => {
  // Selectors to access state from the Redux store
  const onboarding = useSelector(selectOnboarding)
  const user = useSelector((state) => state.auth.user)
  const loading = useSelector((state) => state.general.loading)

  // State variables
  const [isOpenDecline, setIsOpenDecline] = useState(false)

  console.log(onboarding)

  const [onboardingQuestions, setOnboardingQuestions] = useState([])

  // Handlers to open and close the decline confirmation popup
  const handleOpenDeclinePopup = () => setIsOpenDecline(true)
  const handleCloseDeclinePopup = () => setIsOpenDecline(false)

  // Hooks for navigation and dispatching actions
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Navigate back function
  const goBack = () => {
    navigate(-1)
  }

  // useEffect to set onboarding questions based on profile type
  const questions = useMemo(() => {
    try {
      return onboarding?.oneApply?.questions?.length ? onboarding?.oneApply?.questions : onboarding?.questions
    } catch (error) {
      console.log(error)
      return []
    }
  }, [JSON.stringify(onboarding)])
  useEffect(() => {
    const questions = onboarding?.questions?.length ? onboarding?.questions : onboarding?.oneApply?.questions
    const filteredValues = questions?.filter((item) => !Object.keys(item)?.includes('additional_advisor_comments'))
    setOnboardingQuestions(filteredValues)
  }, [onboarding])

  // Function to handle form submission
  const handleSubmit = () => {
    const dataForAccept = {
      meeting_id: onboarding?.currentParent?.meetingId,
      token: user.token,
      payload: {
        reason: 'null',
        questions: [...onboarding?.questions, { additional_advisor_comments: onboarding.additionalAdvisorComments }],
      },
    }
    dispatch(advisorOnbordingActions.submitParentApply(dataForAccept)).then((res) => {
      navigate('/admin_panel/advisor/applications')
    })
  }

  // Function to handle application decline
  const declineApplication = (data) => {
    const questionsArray = []

    if (!onboarding?.questions.length) {
      for (let i = 1; i < 9; i++) {
        const existedQuestion = answers.find((question) => question.id === i)
        if (existedQuestion) {
          questionsArray.push(existedQuestion)
        } else {
          questionsArray.push({ id: i, answer: '' })
        }
      }
    }

    const dataForDecline = {
      meeting_id: onboarding?.currentParent?.meetingId,
      token: user.token,
      payload: {
        reason: data.reason,
        questions: onboarding?.questions,
      },
    }

    dispatch(advisorOnbordingActions.declineParentApply(dataForDecline)).then((res) => {
      handleCloseDeclinePopup()
      navigate('/admin_panel/advisor/applications')
    })
  }

  // Content for the decline confirmation popup
  const contentForDeclineApplicationPopup = {
    title: 'Are you sure you want to decline the onboarding?',
    secondaryText: {
      text: 'What are reasons for declining?',
      style: Styles.popup_text,
    },
    buttons: {
      direction: 'vertical',
      content: [
        {
          id: 1,
          text: 'Continue',
          style: Styles.btn_decline_therapist__delete,
          onClick: declineApplication,
        },
        {
          id: 2,
          text: 'Cancel',
          style: Styles.btn_decline_therapist__cancel,
          onClick: handleCloseDeclinePopup,
        },
      ],
    },
  }

  // useEffect to handle unsaved changes warning before page unload
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.returnValue = 'Are you sure you want to leave? Any unsaved changes will be lost.'
      return event.returnValue
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  const additional_advisor_comments = useMemo(() => {
    const value = questions?.[questions?.length - 1]
    return value?.additional_advisor_comments
  }, [questions])
  return (
    <div style={{ padding: !isModal && '30px' }} className={Styles.main}>
      <div className={Styles.main_container}>
        <div className={Styles.main_container__content}>
          <h4>Summary</h4>
          <div className={Styles.main_container__content__header}>
            <p className={Styles.headline}>Profile details</p>
          </div>
          {loading && (
            <div className={Styles.loader}>
              <Spinner className={Styles.loader} />
            </div>
          )}
          <div className={Styles.child_info}>
            <div className={Styles.child_info__item}>
              <span>Full name</span>
              <p>{isModal ? onboarding.oneApply.profile_name : onboarding.childInfo.profile_name}</p>
            </div>
            <div className={Styles.child_info__item}>
              <span>Birthday</span>
              <p>{isModal ? onboarding.oneApply.birth_date : onboarding.childInfo.birth_date}</p>
            </div>
            <div className={Styles.child_info__item}>
              <span>Language</span>
              <div className={Styles.child_info_languages}>
                {(isModal ? onboarding.oneApply?.language : onboarding.childInfo?.language)?.map((language) => returnLanguagesFromArray(language))?.join(', ')}
              </div>
            </div>
            {onboarding.oneApply?.reason && onboarding.oneApply?.reason !== 'null' && (
              <div className={Styles.child_info__item}>
                <span>Reason of Decline</span>
                <p>{onboarding.oneApply?.reason}</p>
              </div>
            )}
          </div>
          <div className={Styles.answers}>
            <div className={Styles.main_container__content__header}>
              <p className={Styles.headline}>Guided onboarding</p>
              {/* {!isModal && <EditIcon onClick={goBack} />} */}
            </div>
            {onboardingQuestions?.map((question) => (
              <div key={question?.id}>
                <div className={Styles.text_container}>
                  <h6 className={Styles.number}>{question?.id + 1}</h6>
                  <div className={Styles.text}>
                    <h2>{question?.main_statement}</h2>
                    <p className={Styles.answer_text}>{question?.advisor_comment || 'Skipped'}</p>
                  </div>
                </div>
              </div>
            ))}
            <div className={Styles.text_container}>
              <div className={Styles.text}>
                <h2>Additional Comments</h2>
                <p className={Styles.answer_text}>{onboarding?.additionalAdvisorComments || additional_advisor_comments || 'Skipped'}</p>
              </div>
            </div>
          </div>
          {!isModal && (
            <div className={Styles.btns}>
              <div className={Styles.btns__back} onClick={goBack}>
                <ArrowLeft />
                <span>Back</span>
              </div>
              <div className={Styles.action_btns}>
                <button className={Styles.btns__decline} onClick={handleOpenDeclinePopup}>
                  Decline
                </button>
                <button className={Styles.btns__submit} onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
        {isOpenDecline && (
          <ConfirmationPopup open={isOpenDecline} onClose={handleCloseDeclinePopup} content={contentForDeclineApplicationPopup} width={'436px'} isDeclinePopup={true} />
        )}
      </div>
    </div>
  )
}
